@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.ScanCardOverlay {
    position: relative;
    background: transparent !important;

    overflow: visible !important;
    padding: 0 !important;

    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }

    &--direction-rtl {
        direction: rtl;
    }

    &__header {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        margin-top: pxToVw(-56px);
        text-align: center;

        @include heading-h3-32;
        color: $milk;
    }

    &__card__container {
        display: flex;

        overflow: auto;
        padding: pxToVw(32px);

        &::-webkit-scrollbar {
            display: none;
        }

        > div {
            transition: margin 250ms ease-in; // to prevent card snap due to margin when exiting
        }

        > div + div {
            margin-right: pxToVw(16px);
        }

        &--few-item {
            justify-content: center;
        }
    }

    &__button {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 0;

        margin-bottom: pxToVw(-56px);
        width: pxToVw(363px);
        border-radius: pxToVw(8px);

        > button {
            width: 100%;
            padding: pxToVw(12px) 0;
        }
    }

    &__placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: pxToVw(20px);

        &__icon {
            color: $honey;
            font-size: pxToVw(80px);
        }

        &__text {
            &__container {
                padding-top: pxToVw(24px);
                max-width: pxToVw(363px);
                text-align: center;

                > div + div {
                    margin-top: pxToVw(4px);
                }
            }

            &__title {
                max-width: pxToVw(300px);
                margin: auto;
                color: $milk;
                @include heading-h3-32;
            }

            &__description {
                color: $whiteOverlay80;
                @include subtitle-medium-18;
            }
        }
    }
}
