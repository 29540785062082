@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.ConfirmationModal {
    width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        padding: pxToVw(16px);
        text-align: center;
    }

    &__icon {
        margin: 0 auto;
        font-size: pxToVw(80px);
        color: $honey;
    }

    &__text {
        &__wrapper {
            margin-top: pxToVw(24px);

            > div {
                width: pxToVw(300px);
            }

            div + div {
                margin-top: pxToVw(4px);
            }
        }

        &__primary {
            color: $black;
            @include heading-h4-24;
        }

        &__secondary {
            color: $blackLight;
            @include body-regular-18;
        }
    }

    &__button {
        flex: 1;
        border-radius: pxToVw(8px);

        > button {
            width: 100%;
        }

        &__wrapper {
            margin-top: pxToVw(24px);
            display: flex;
            color: $milk;
            justify-content: space-between;
        }

        &__divider {
            width: pxToVw(16px);
        }
    }
}
