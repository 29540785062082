@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.Toast {
    color: $milk;
    position: fixed;
    top: pxToVw(-80px);
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 2;

    max-width: pxToVw(406px);

    transition: 0.4s;

    &__visible {
        top: pxToVw(38px);
    }

    &__bar {
        background-color: var(--background-clr, $matcha);
        border-radius: pxToVw(50px);
        padding: pxToVw(12px) pxToVw(24px);
        display: flex;
        align-items: center;

        div + div {
            margin-left: pxToVw(8px);
        }
    }

    &__icon__wrapper {
        padding: pxToVw(2px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        font-size: pxToVw(20px);
    }

    &__message {
        @include title-bold-18;

        &--align-center {
            text-align: center;
        }
    }

    &--alert {
        --background-clr: #{$mentaiDark};
    }
    &--error {
        --background-clr: #{$sauce};
    }
}
