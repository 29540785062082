@import "./assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-utils.module";
@import "./assets/styles/hangry-typographies.module";

.touchable-opacity {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.touchable-opacity:active {
  opacity: 0.7;
}

.focus-outline {
  outline-color: $aquaLight;
  transition-property: outline outline-offset;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  &:hover {
      outline: pxToVw(4px) solid #{$aquaLight}99;
      outline-offset: pxToVw(4px);
  }
  &:focus {
      outline: pxToVw(4px) solid $aquaLight;
      outline-offset: pxToVw(4px);
  }
}