@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.Outlet {
    background-color: $overlay90;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: pxToVw(128px) pxToVw(32px) pxToVw(32px);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */

    &__form {
        background-color: $milk;
        border-radius: pxToVw(8px);
        padding: pxToVw(24px) 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        height: pxToVw(552px);

        &__top__section {
            padding: 0 pxToVw(24px);
        }

        &__title {
            @include title-bold-20;
        }

        &__input__wrapper {
            display: flex;
            align-items: center;

            width: pxToVw(400px);
            padding: pxToVw(12px);
            margin-top: pxToVw(24px);

            border-radius: pxToVw(8px);
            border: solid $greyLight;
            border-width: pxToVw(2px);

            &:hover {
                border-color: #{$aquaLight}99;
            }
            &:focus-within {
                border-color: $aquaLight;
            }

            > input {
                margin-left: pxToVw(8px);
                width: 100%;
                @include body-regular-14;
                border: none;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $greyLight;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $greyLight;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $greyLight;
                }
            }
        }

        &__icon {
            color: $black;

            &__wrapper {
                padding: pxToVw(4px);
                overflow: hidden;
            }
        }
    }

    &__results {
        &__wrapper {
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            scroll-behavior: smooth;

            margin-top: pxToVw(8px);
            flex: 1;
            display: flex;
            width: 100%;
        }

        &__default__text {
            margin: auto;
            @include body-regular-14;
            width: pxToVw(400px);
            color: $blackLight;
            text-align: center;
        }

        &__loading {
            margin: auto;
            --loader-color: #{$black};
        }

        &__list {
            width: 100%;
            border-radius: pxToVw(8px);

            &__item {
                @include title-bold-18;
                color: $black;
                padding: pxToVw(10px) pxToVw(16px);
                display: flex;

                &__wrapper {
                    padding: pxToVw(6px) pxToVw(16px);

                    &:nth-child(even) {
                        background-color: $greyLighter;
                    }
                    > div {
                        border-radius: pxToVw(8px);

                        &:hover {
                            outline: solid #{$aquaLight}99;
                            outline-width: pxToVw(2px);
                        }
                        &:focus {
                            outline: solid $aqua;
                            outline-width: pxToVw(2px);
                        }
                    }
                }
            }
        }
    }

    @keyframes pulse {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
}
