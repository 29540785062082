@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.ScanCard {
  background-color: $milk;
  border-radius: pxToVw(8px);
  width: pxToVw(332px);
  height: pxToVw(284px);
  direction: ltr;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:global(.enter) {
    opacity: 0;
  }

  &:global(.enter-active) {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  &:global(.exit-active) {
    margin: 0; // to prevent card snap due to margin when exiting
    opacity: 0;
    width: 0;
    transition: opacity 100ms ease-out, width 200ms ease-out;
  }

  &__top__section {
    padding: pxToVw(24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__added__amount {
    @include heading-h3-32;
    color: $matcha;
  }

  &__name {
    @include title-bold-20;
    color: $black;
    margin-top: pxToVw(8px);
    width: pxToVw(284px);
    height: pxToVw(56px);
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  &__detail {
    &__container {
      display: flex;
      width: 100%;
      margin-top: pxToVw(16px);
      justify-content: space-evenly;
    }

    &__wrapper {
      text-align: center;

      div + div {
        margin-top: pxToVw(4px);
      }
    }

    &__title {
      @include footer-medium-10;
      color: $blackLight;
    }

    &__value {
      @include title-bold-14;
      color: $black;
    }
  }

  &__bottom__section {
    display: flex;
    padding: pxToVw(16px);
  }

  &__button {
    flex: 1;
    border-radius: pxToVw(8px);

    > button {
      width: 100%;
    }
  }

  &--history {
    height: auto;

    transition-property: outline outline-offset;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    &:hover {
      outline: pxToVw(4px) solid #{$aquaLight}99;
      outline-offset: pxToVw(4px);
    }
    &:focus-within {
      outline: pxToVw(4px) solid $aquaLight;
      outline-offset: pxToVw(4px);
    }
  }
}
