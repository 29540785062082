@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.Welcome {
    background-color: $overlay90;
    height: 100vh;

    display: flex;
    flex-direction: column;

    &__body {
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__icon {
        color: $honey;
        font-size: pxToVw(80px);
    }

    &__content__wrapper {
        margin-top: pxToVw(24px);
    }

    &__title {
        @include heading-h3-32;
        color: $milk;
    }

    &__description {
        @include subtitle-medium-18;
        color: $whiteOverlay80;

        margin-top: pxToVw(4px);
    }

    &__button {
        display: inline-block;
        border-radius: pxToVw(8px);
        width: pxToVw(216px);
        margin: pxToVw(24px) auto 0;

        > button {
            padding: pxToVw(12px) 0;
            width: 100%;
        }
    }
}
