@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.EditStockModal {
    width: pxToVw(332px) !important;
    position: relative;
    overflow: visible !important;

    --main-bg-color: brown;

    &__header {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;

        @include heading-h3-32;
        color: $milk;
        top: pxToVw(-64px);
    }

    &__wrapper {
        padding: pxToVw(8px) pxToVw(8px) 0;
    }

    &__title {
        color: $black;
        @include title-bold-20;
        text-align: center;
    }

    &__input {
        &__container {
            margin-top: pxToVw(24px);

            padding-bottom: pxToVw(3px);
            border-bottom: solid $greyLight;
            border-bottom-width: pxToVw(2px);

            &:focus-within {
                border-color: $aquaLight;
            }
        }

        &__title {
            color: $blackLight;
            @include caption-medium-12;
        }

        &__box {
            margin-top: pxToVw(8px);
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input + div {
                margin-left: pxToVw(6px);
            }
        }

        &__field {
            width: 100%;
            @include title-bold-20;

            border: none;
            outline: none;
            background-color: transparent;

            color: $grey;

            &:focus, &:hover {
                color: var(--focus-clr, $black);

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $black;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $black;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $black;
                }
            }

            &--danger {
                --focus-clr: #{$sauce};
            }
        }

        &__units {
            @include caption-bold-12;
            color: $grey;
        }
    }

    &__button__container {
        margin-top: pxToVw(40px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__button {
        &__wrapper {
            flex: 1;
        }

        &__spacer {
            width: pxToVw(8px);
        }

        border-radius: pxToVw(8px);
        flex: 1;
        
        > button {
            width: 100%;
        }

        &--secondary {
            background-color: $milk;
            color: $sauce;
        }

        &:disabled {
            background-color: $greyLight;
            color: $milk;
            border: none;
        }
    }
}
