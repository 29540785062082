@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.ZoomScale {
  display: flex;
  align-items: center;
  margin-right: pxToVw(16px);
  user-select: none;
  .scale {
    width: pxToVw(24px);
    height: pxToVw(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  svg {
    font-size: pxToVw(24px);
  }
  span {
    @include body-regular-16;
    text-align: center;
    width: pxToVw(50px);
    margin: 0 pxToVw(8px) 0 pxToVw(12px);
  }
}
