@import './assets/styles/hangry-constants.module';
@import './assets/styles/hangry-utils.module';

html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --widthScale: 1;
  --ui-kit-design-width: #{strip-unit($designWidth)}; // Required
  --ui-kit-design-height: #{strip-unit($designHeight)}; // Required
}

p {
  margin: 0;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}