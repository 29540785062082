@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.Home {
    background-color: $overlay90;
    height: 100vh;

    display: flex;
    flex-direction: column;

    &__card__container {
        flex: 1;
        display: flex;
        padding: pxToVw(32px);
        overflow-x: scroll;

        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        &__grid__wrapper {
            margin: auto 0;
            display: flex;
            flex-wrap: wrap;
            gap: pxToVw(16px);
        }
    }

    &__scanCardOverlay__wrapper {
        > div {
            > div {
                padding: 0 !important;
            }
        }
    }

    &__loading__indicator {
        position: absolute;
        z-index: 1;
        right: pxToVw(50px);
        bottom: pxToVw(50px);
        padding: pxToVw(4px);
        > svg {
            color: $aquaLight;
            font-size: pxToVw(42px);

            animation: spin 1s linear infinite;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
