// Mobile
$designMobileWidth: 1440px; // screen width used on design to scale into
$designMobileHeight: 900px; // screen height used on design to scale into

// Desktop
$designWidth: 1440px; // screen width used on design to scale into
$designHeight: 900px; // screen height used on design to scale into

$enableScaling: true;

:export {
  designMobileWidth: $designMobileWidth;
  designMobileHeight: $designMobileHeight;
  designWidth: $designWidth;
  designHeight: $designHeight;

  enableScaling: $enableScaling;
}
