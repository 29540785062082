@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.Header {
    color: $milk;
    padding: pxToVw(32px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__time {
        &__top__secion {
            @include subtitle-medium-16;
        }

        &__bottom__secion {
            @include heading-h3-32;
        }
    }

    &__actions {
        display: flex;
        @include title-bold-16;

        div + div {
            margin-left: pxToVw(12px);
        }

        &__btn {
            border-radius: pxToVw(8px);

            > button {
                padding: pxToVw(12px);
            }
        }
    }

    &__connection__indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: pxToVw(8px);
        padding-right: pxToVw(12px);

        &__icon {
            color: $matcha;

            &--danger {
                color: $sauceLight;
            }
        }

        &__text {
            @include subtitle-medium-16;
        }
    }
}
