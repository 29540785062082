@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.LoadingIndicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .overlay {
        border-radius: pxToVw(8px);
        width: pxToVw(80px);
        height: pxToVw(80px);
        background-color: $overlay80;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
