@import "src/assets/styles/hangry-colors.module";
@import "src/assets/styles/hangry-typographies.module";
@import "src/assets/styles/hangry-utils.module";

.StockCard {
  height: calc(#{pxToVw(310px)} * var(--widthScale));
  width: calc(#{pxToVw(320px)} * var(--widthScale));
  border-radius: calc(#{pxToVw(8px)} * var(--widthScale));
  background-color: $milk;
  padding: calc(#{pxToVw(24px)} * var(--widthScale));
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: $black;

  &__top__section {
    display: flex;
    justify-content: space-between;

    &__title {
      @include heading-h4-24;
      font-size: calc(#{pxToVw(24px)} * var(--widthScale));
      line-height: calc(#{pxToVw(32px)} * var(--widthScale));
      width: calc(#{pxToVw(228px)} * var(--widthScale));
      height: calc(#{pxToVw(96px)} * var(--widthScale));
      color: var(--product-name-clr);

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &__icon {
      font-weight: 900;
      font-size: calc(#{pxToVw(24px)} * var(--widthScale));
      line-height: calc(#{pxToVw(24px)} * var(--widthScale));

      color: var(--ellipsis-clr, $black);
    }
  }

  &__middle__section {
    display: flex;

    &__expiration__badge {
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--badge-clr, $mentaiDark);
      background-color: $milk;
      padding: calc(#{pxToVw(12px)} * var(--widthScale))
        calc(#{pxToVw(16px)} * var(--widthScale));
      border-radius: calc(#{pxToVw(50px)} * var(--widthScale));

      div + div {
        margin-left: calc(#{pxToVw(8px)} * var(--widthScale));
      }

      &__icon {
        font-size: calc(#{pxToVw(16px)} * var(--widthScale));
      }

      &__message {
        @include title-bold-18;
        font-size: calc(#{pxToVw(18px)} * var(--widthScale));
        line-height: calc(#{pxToVw(26px)} * var(--widthScale));
      }
    }
  }

  &__bottom__section {
    div + div {
      margin-top: calc(#{pxToVw(4px)} * var(--widthScale));
    }
    &__info {
      @include label-bold-12;
      font-size: calc(#{pxToVw(12px)} * var(--widthScale));
      line-height: calc(#{pxToVw(12px)} * var(--widthScale));
      letter-spacing: calc(#{pxToVw(1.25px)} * var(--widthScale));
    }
    &__status {
      @include heading-h3-32;
      font-size: calc(#{pxToVw(32px)} * var(--widthScale));
      line-height: calc(#{pxToVw(40px)} * var(--widthScale));
      color: var(--status-clr, $matcha);
    }
  }

  &__icon__wrapper {
    padding: calc(#{pxToVw(4px)} * var(--widthScale));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--alert {
    color: $mentaiDark;
    --status-clr: #{$mentaiDark};
    --product-name-clr: #{$black};
  }

  &--warn {
    background-color: $honey;
    color: $black;
    --status-clr: #{$black};
  }

  &--danger {
    background-color: $sauce;
    color: $milk;
    --ellipsis-clr: #{$milk};
    --status-clr: #{$milk};
    --badge-clr: #{$sauce};
  }
}
